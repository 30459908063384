<template>
  <div class="navbar-header d-xl-block d-none">
    <ul class="nav navbar-nav">
      <li class="nav-item">
        <navbar-logo />
      </li>
    </ul>
  </div>
</template>

<script>
import NavbarLogo from '@/views/modules/core/partials/NavbarLogo.vue'

export default {
  components: {
    NavbarLogo,
  },
}
</script>

<style>

</style>
